<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact>Historia Clinica - {{ name_historia }}</page-header-compact>
    <br />
    <div class="container-xl px-4">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <br />
      <wizard
        :steps="steps.length"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
              v-for="(component, i) in steps"
              :key="`tabs_${i}`"
              :title="component.title"
              :step="i+1"
              :active="(i+1)===current"
              :id="`step${i+1}`"
              v-on:tab-click="next({ prev: current, current: (i+1) })"
          >
          </wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                  :is="currentComponent.ref"
                  :ref="currentComponent.ref"
                  v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component :idHistoria="idHistoria" :usuario="usuario"></remision-component>
            <incapacidad-component :idHistoria="idHistoria" :usuario="usuario"></incapacidad-component>
            <finalizar-historia :id-usuario="usuario.id" :id-historia="idHistoria" :codigoHistoria="'PLAF'"></finalizar-historia>
          </div>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>

import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import examenFisicoComponent from "../base/examenFisico/examenFisico";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import revisionSistemasComponent from "../base/baseUrgencia/revision";
import ordenComponent from "../base/orden/ordenComponent";
import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
import controlComponent from "./controlComponent";
import tipoPatologiaComponent from "../base/baseTipoPatologia/tipoPatologia";
import resumenHcComponent from "./resumenHc/resumenHcComponent";
import historialAnticoncepcionalComponent from "../base/baseHistorialAnticoncepcional/indexHistorialAnticoncepcional";
import embarazosAnterioresComponent from "../base/baseEmbarazosAnteriores/indexEmbarazosAnteriores";
import historialMenstrualComponent from "../base/baseHistoriaMenstrual/indexHistoriaMenstrual";
import riesgoReproductivo from "@/views/historias/base/riesgoReproductivo/riesgoReproductivo";
import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
import intervencionesComponent from "../base/intervenciones/intervenciones";
import recomendacionesComponent from "../base/recomendaciones/recomendaciones";
import RemisionComponent from "../base/baseRemision/index";
import incapacidadComponent from "../base/baseIncapacidades/index";
import metodoPlanificacion from "../base/baseMetodoPlanificacion/index";

export default {
  components: {
    FinalizarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    examenFisicoComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    impresionDXComponent,
    modalInformacionUsuario,
    cardInformacionUsuario,
    revisionSistemasComponent,
    ordenComponent,
    controlComponent,
    formulaMedicaComponent,
    tipoPatologiaComponent,
    resumenHcComponent,
    historialAnticoncepcionalComponent,
    embarazosAnterioresComponent,
    historialMenstrualComponent,
    riesgoReproductivo,
    intervencionesComponent,
    recomendacionesComponent,
    RemisionComponent,
    incapacidadComponent,
    metodoPlanificacion
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      idTipoHistoria : '',
      usuario: {},
      current: 1,
      checkpoint : 1,
      name_historia: "",
    };
  },
  methods: {
    prev(step) {

      this.current = step.current;
      this.activeTab();
      
    },
    next(step) {
      
      if(step.prev <= this.checkpoint && step.current-1 > step.prev){
        this.activeTab();
        return;
      }
      
      const refComponent = this.steps[step.prev - 1].ref;
      
      if (typeof this.$refs[refComponent].save !== "undefined") {
        this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if(step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      
      const response = await historiaClinicaService.show(id);
      
      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;
      this.idTipoHistoria = response.data.id_tipo_historia;
      this.name_historia = response.data.tipo_historia.nombre;

      this.activeTab()

    },
    activeTab(){
      document.querySelectorAll(`a.active`).forEach(a =>{ a.classList.remove('active') });
      document.querySelector(`a[href='${this.steps[this.current - 1].stepId}']`).classList.add("active");
    }
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {
      if (this.currentComponent.ref === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
       if (this.currentComponent.ref === "revisionSistemasComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
       
      
      if (this.currentComponent.ref === "examenFisicoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }

      if (this.currentComponent.ref === "controlComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario : this.usuario,
          idTipoHistoria : this.idTipoHistoria,
        }
      }
      if (this.currentComponent.ref === "historialAnticoncepcionalComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
        }
      }
       if (this.currentComponent.ref === "embarazosAnterioresComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
        }
      }
      if (this.currentComponent.ref === "historialMenstrualComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
        }
      }
      if (this.currentComponent.ref === "ordenComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario
        }
      }

      if (this.currentComponent.ref === "formulaMedicaComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario,
        }
      }
      if (this.currentComponent.ref === "impresionDXComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }

     if (this.currentComponent.ref === "riesgoReproductivo") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario:this.usuario
        }
      }

      if (this.currentComponent.ref === "intervencionesComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idTipoHistoria : this.idTipoHistoria
        }
      }

       if (this.currentComponent.ref === "recomendacionesComponent") {
        return {
          idHistoria: this.idHistoria,
          idTipoHistoria : this.idTipoHistoria,
          finalizada: this.finalizada,
        }
      }
     
      if (this.currentComponent.ref === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario:this.usuario.id,
        }
      }

      if (this.currentComponent.ref === "metodoPlanificacion") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario : this.usuario,
          idTipoHistoria : this.idTipoHistoria,
        }
      }

      return {}
    },
    currentComponent() {
      return this.steps[this.current - 1];
    },
    steps () {
      let components = [
        {sexo : 'A',title : 'Motivo Consulta', ref: "motivoConsultaComponent", validate: true },
        {sexo : 'A',title : 'Examen Físico', ref: "examenFisicoComponent", validate: true },
        {sexo : 'A',title : 'Revisión por Sistemas', ref: "revisionSistemasComponent", validate: true },
        {sexo : 'A',title : 'Control', ref: "controlComponent", validate: false },
        {sexo : 'A',title : 'Historial Anticoncepcional', ref: "historialAnticoncepcionalComponent", validate: false },
        {sexo : 'F',title : 'Embarazos Anteriores', ref: "embarazosAnterioresComponent", validate: false },
        {sexo : 'F',title : 'Historial Mestrual', ref: "historialMenstrualComponent", validate: true },
        {sexo : 'A',title : 'Ordenes', ref: "ordenComponent", validate: false },
        {sexo : 'A',title : 'Formula Médica', ref: "formulaMedicaComponent", validate: false },
        {sexo : 'A',title : 'Método Anticonceptivo', ref: "metodoPlanificacion", validate: true },
        {sexo : 'A',title : 'Impresión DX', ref: "impresionDXComponent", validate: true },
        {sexo : 'F',title : 'Riesgo Reproductivo', ref: "riesgoReproductivo", validate: true },
        {sexo : 'A',title : 'Plan de Intervención', ref: "intervencionesComponent", validate: false },
        {sexo : 'A',title : 'Recomendaciones', ref: "recomendacionesComponent", validate: false },
        {sexo : 'A',title : 'Resumen', ref: "resumenHcComponent", validate: false },
      ];

      return components.filter(x => x.sexo === this.usuario.sexo || x.sexo === 'A');
    }
  },
}
</script>

<style scoped></style>
