<template>
    <main>
        <button type="button" class="btn btn-light text-primary mb-2" data-toggle="modal" data-target=".bd-example-modal-lg"><i class="fa fa-eye fa-fw"></i>Ver Hoja de registro de puntuación</button>

        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Hoja de Registro de puntuación de la escala abreviada de desarrollo
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <!-- Recorre cada rango: RANGO1, RANGO2, etc. -->
                            <table class="table table-responsive table-bordered table-sm"
                                style="table-layout: fixed; width: 100%;">


                                <tr v-for="(index, rango) in dataSheetsRegister.RANGOS" :key="index">
                                    <td class="td-style">{{ rango }}</td>

                                    <!-- Recorre cada categoría dentro del rango -->
                                    <div>
                                        <td class="td-style">DM</td>
                                        <td class="td-style" v-for="n in 101" :key="n">{{ n - 1 }}</td>

                                <tr v-for="(values, dimension) in index.DIMENSIONES" :key="`di_${dimension}`">
                                    <td class="td-style">{{ dimension }}</td>

                                    <!-- Recorre cada clave-valor dentro de una categoría -->
                                    <td class="td-style text-center" v-for="(color, key) in values" :key="key"
                                        :class="getClass(color)">
                                        <div v-for="(item, i) in dimensiones" :key="`d_${i}`">
                                            <span class="font-weight-bolder" style="font-size: 8px;"
                                                v-if="rango === `${calculateRango()}` && key === `${item.puntuacion_tipica}` && dimension === dimensionAbbr(item.nombreNormalizado)">X</span>
                                        </div>
                                    </td>

                                </tr>
                        </div>
                        </tr>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button
                type="button"
                class="btn btn-light btn-sm"
                data-dismiss="modal"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </main>
</template>
<script>
import sheetsRegister from "../../../../../assets/datasheets/escalaAbreviada/sheetsRegister.json";

export default {
    props: ['dimensiones', 'rango_actual'],
    data() {
        return {
            dataSheetsRegister: sheetsRegister
        }
    },
    methods: {
        getClass(key) {
            return {
                'table-red': key === 'R',
                'table-success': key === 'V',
                'table-warning': key === 'A'
            };
        },
        calculateRango() {
            return this.rango_actual.nombre.replace(" ", "");
        },
        dimensionAbbr(dimension) {
            if (dimension === 'MOTRICIDAD_GRUESA') return 'MG';
            if (dimension === 'MOTRICIDAD_FINO_ADAPTATIVA') return 'MF';
            if (dimension === 'AUDICION_LENGUAJE') return 'AL';
            if (dimension === 'PERSONAL_SOCIAL') return 'PS';
            return ''; // Or some default value
        }
    }
}
</script>
<style scoped>
.td-style {
    width: 15px;
    height: 5px;
    font-size: 10px;
    padding: 1px
}

.tr-style {
    width: 15px;
    height: 5px;
    font-size: 8px;
    padding: 1px
}
</style>